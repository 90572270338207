<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-back" @click="goBack()">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                    <g id="arrow-left-circle" transform="translate(-1 -1)">
                        <circle id="Ellipse_8" data-name="Ellipse 8" cx="10" cy="10" r="10" transform="translate(2 2)" fill="none" stroke="#409edc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <path id="Path_238" data-name="Path 238" d="M12,8,8,12l4,4" fill="none" stroke="#409edc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        <line id="Line_128" data-name="Line 128" x1="8" transform="translate(8 12)" fill="none" stroke="#409edc" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    </g>
                </svg>
                Back to landing pages
            </div>
            <div class="page_head-item">
                <div class="page_head-title">
                    CHOOSE AN AD TEMPLATE
                </div>
            </div>
        </div>

        <div class="page_content">
            <div class="page_templates">

                <div class="template_item" v-for="template in allTemplates">
                    <div class="template_item-img"
                         :style="{ backgroundImage: 'url(' + template.img + ')' }"
                    ></div>
                    <div class="template_item-footer">
                        <div class="template_item-title">{{template.title}}</div>
                        <div class="template_item-btn-container">
                            <router-link :to="'/templateItem/' + template.id">
                                <b-button class="template_item-btn light">Preview</b-button>
                            </router-link>
                            <b-button class="template_item-btn dark">Select Template</b-button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import template_1 from '../assets/images/template_1.png'
    import template_2 from '../assets/images/template_2.png'
    export default {
        name: "ChooseTemplate",
        data() {
            return {
                borderPosition: true,
                allTemplates: [
                    {
                        img: template_1,
                        title: 'Seller Template #1',
                        id: 1

                    },
                    {
                        img: template_2,
                        title: 'Buyer Template #2',
                        id: 2

                    }
                ]

            }
        },
        methods: {
            goBack() {
                this.$router.go(-1)
            },
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        display: flex;
        flex-direction: column;
        /*align-items: center;*/
        max-width: 1700px;
        width: 100%;
        margin: 0 auto;
        transition: .4s;
        &.straight_length {
            max-width: 1600px;
            transition: .4s;
        }
        &.straight_length {
            max-width: 1600px;
            transition: .4s;
        }
    }
    &_head {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 30px 30px 5px;
        &-back {
            display: flex;
            align-items: center;
            color: $light_blue;
            cursor: pointer;
            & svg {
                margin-right: 8px;
            }
        }

        &-item {
            display: flex;
            align-items: center;
            padding: 30px 0 10px;
        }

        &-title {
            color: $main_blue;
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 0;
            text-transform: uppercase;
        }

    }
    &_content {


    }
    &_templates {
        padding: 20px 0;
        display: flex;
        & .template {
            &_item {
                width: 490px;
                height: 338px;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;
                margin: 0 40px 40px 0;
                &-img {
                    width: 100%;
                    height: 264px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                &-footer {
                    display: flex;
                    justify-content: space-between;
                    padding: 18px 14px;
                }
                &-title {
                    color: $main_blue;
                    font-weight: 600;
                }
                &-btn {
                    border-radius: 5px;
                    border: 1px solid $light_blue;
                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                    &.light {
                        background: $white;
                        color: $light_blue;
                        margin: 0 10px;
                    }
                    &.dark {
                        color: $white;
                        background: $light_blue;
                    }
                }

            }
        }
    }
}
</style>